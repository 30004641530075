import React from 'react';
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import Link from '../../../components/shared/link'
import MapContainer from '../../../components/map'
import ContactForm from '../../../components/contact-form'
import { ContactSectionWrap, ContactWrap, ContactFormWrap } from './contact-form.stc'
import {ContactInfoWrap, SingleInfo, MapWrapper} from './contact-info-update.stc'



const ContactFormSection = ({ headingStyle, textStyle, infoTextStyle, infoHeadingStyle, infoLinkStyle }) => {
    const contactData = useStaticQuery(graphql`
        query ContactBannerQuery {
            pagedataJson(jsonId: {eq: "contact_page_data"}) {
                title
                subtitle
                banner_img {
                  childImageSharp {
                    fluid(maxWidth: 1920, maxHeight: 2500, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                      presentationWidth
                      presentationHeight
                    }
                  }
                }
            }
            site{
                siteMetadata {
                  form_url
                  contact {
                    company_address
                    company_email
                    phone
                  }
                }
            }
        }       
    `);
    
    
    // const imageData = contactData.pagedataJson.banner_img.childImageSharp.fluid
    const { siteMetadata: { form_url } } = contactData.site;
    const {company_address, company_email, phone} = contactData.site.siteMetadata.contact

    return (
        <ContactSectionWrap style={{backgroundColor: 'white'}}>
            <div className="row align-items-center">
                <div className="col-2 align-items-center">
                    <ContactInfoWrap>
                        {company_address && (
                            <SingleInfo className="wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1000ms">
                                <Text {...infoTextStyle}>Address</Text>
                                <Heading {...infoHeadingStyle}>{company_address}</Heading>
                            </SingleInfo>
                        )}
                        {company_email && (
                            <SingleInfo className="wow fadeInLeft" data-wow-delay="500ms" data-wow-duration="1000ms">
                                <Text {...infoTextStyle}>Email</Text>
                                <Heading {...infoHeadingStyle}>
                                    <Link {...infoLinkStyle} path={`mailto:${company_email}`}>{company_email}</Link>
                                </Heading>
                            </SingleInfo>
                        )}

                        <SingleInfo className="wow fadeInLeft" data-wow-delay="700ms" data-wow-duration="1000ms">
                            <Text {...infoTextStyle}>Phone</Text>
                            {phone && <Heading {...infoHeadingStyle}><Link {...infoLinkStyle} path="/">{phone}</Link></Heading>}
                            {/*{phone2 && <Heading {...infoHeadingStyle}><Link {...infoLinkStyle} path="/">{phone2}</Link></Heading>}*/}
                        </SingleInfo>
                    </ContactInfoWrap>
                    <MapWrapper>
                        <MapContainer/>
                    </MapWrapper>
                </div>
                <div className="col-2 offset-1">
                    <ContactWrap>
                        <div className="rn-project-meta-inner">
                            <Heading {...headingStyle}>Lets talk about<br/>how we can help.</Heading>
                        </div>
                        <ContactFormWrap>
                            <ContactForm url={form_url} />
                        </ContactFormWrap>
                    </ContactWrap>
                </div>
            </div>
        </ContactSectionWrap>
    )
}

ContactFormSection.propTypes = {
    headingStyle: PropTypes.object,
    textStyle: PropTypes.object,
    infoTextStyle: PropTypes.object,
    infoHeadingStyle: PropTypes.object,
    infoLinkStyle: PropTypes.object
}

ContactFormSection.defaultProps = {
    headingStyle: {
        fontSize: '40px',
        lineHeight: '55px',
        color: 'primary',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '24px',
                lineHeight: 'initial'
            }
        }
    },
    textStyle: {
        as: 'span',
        color: '#000000',
        fontweight: 500,
        fontSize: '16px',
        texttransform: 'uppercase',
        letterspacing: '1px',
        display: 'inline-block',
        mb: '17px',
        lineHeight: 1,
        responsive: {
            medium: {
                fontSize: '12px',
                mb: '10px',
            }
        }
    },
    infoTextStyle: {
        fontSize: '12px',
        color: '#000',
        opacity: 0.8,
        fontweight: 200,
        texttransform: 'uppercase',
        mb: '5px'
    },
    infoHeadingStyle: {
        fontSize: '14px',
        fontweight: 1200,
        color: '#000',
        letterspacing: '0.5px',
        mb: 0,
    },
    infoLinkStyle: {
        color: '#000'
    }
}

export default ContactFormSection;