import React from "react"
import SEO from '../components/seo';
import Layout from '../containers/layout/layout'
import ContactFormSection from '../containers/contact/contact-form'


export default () => (
    <Layout headerLayout={3}  headerStyle="darkHeader">
        <SEO title="Atlasopen - Contact Us" description="Submit an Enquiry for Data Engineering or Software Development Services Today"/>
        <ContactFormSection/>
    </Layout>
)
