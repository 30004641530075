import React from 'react'
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';

export const MapContainer = (props) => {
  const onMarkerClick = (e) => {
    console.log(e)
  }
  return (
    <Map
      google={props.google}
      zoom={14}
      initialCenter={{
          lat: -37.79545026464609,
          lng: 144.98452577136442
      }}
    >

      <Marker onClick={onMarkerClick}
              name={'Current location'} />
    </Map>
  );
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyA0e_t6dR2OtIsoK3hYl8NRHFVzTPnfYhI')
})(MapContainer)

