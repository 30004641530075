import styled from 'styled-components';
import { device } from '../../../theme'

export const ContactSectionWrap = styled.div`
    background-color: #f8f8f8;
    position: relative;
    padding: 8rem 1rem;
    @media ${device.medium}{
        .col-2{
            flex-basis: 100vw;
            max-width: 100vw;
        }
        .col-2.offset-1{
            flex-basis: 80vw;
            max-width: 80vw;
        }
    }
`;


export const ContactWrap = styled.div`
    margin-top: 4rem;
    @media ${device.large}{
        padding: 20px 0;
    }
    @media ${device.medium}{
        padding: 70px 0;
    }
    @media ${device.small}{
        padding: 10vw 2rem;
        margin: 0 -2rem;
        margin-right: 5px;
    }
`;

export const ContactFormWrap = styled.div`
    @media ${device.medium}{
        margin-top: 50px;
    }
`;