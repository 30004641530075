import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from "react-hook-form";
import Button from '../shared/button'
import Form, { Input, Textarea, FormGroup } from '../shared/form'
import { FormWrap } from './contact-form.stc'
import firebase from "../../../firebase/firebase";

// import {useGoogleReCaptcha,   GoogleReCaptcha} from "react-google-recaptcha-v3"

const ContactForm = ({ inputStyle, textareaStyle, url }) => {

    // const { executeRecaptcha } = useGoogleReCaptcha();
    // const [token, setToken] = useState( executeRecaptcha("login_page"))

    const { register, handleSubmit, errors } = useForm({
        mode: "onBlur"
    })
    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });
    const [value, setValue] = useState({
        firstname: '',
        lastname: '',
        email: '',
        number: '',
        companyname: '',
        message: ''
    });

    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            form.reset();
            setValue({
                firstname: '',
                lastname: '',
                email: '',
                number: '',
                companyname: '',
                message: ''
            })
        }
    };
    const onSubmit = async (data, e) => {
        try {
            // console.log(token);
            // console.log(a);
            const form = e.target;
            // console.log(data)
            // // setServerState({submitting: true});
            // // const response = await axios.post("https://us-central1-fir-functions-api-7fcdb.cloudfunctions.net/user", data
            // // ).catch(err => {
            // //     handleServerResponse(false, err.response.data.error, form);
            // // });
            // // const response1 = await axios.post("https://getform.io/f/076cc519-914a-4ec9-9205-451872073e07", data
            // // ).catch(err => {
            // //     handleServerResponse(false, err.response.data.error, form);
            // // });
            // //
            // // try {
            // //     const result = executeRecaptcha('contact-form').then(r=> console.log(r));
            // //     console.log(result);
            // // }
            // // catch(e){
            // //     console.log(e);
            // // }
            // // console.log(result);
            // // setToken(result) //--> grab the generated token by the reCAPTCHA
            // // Prepare the data for the server, specifically body-parser
            //
            // const r = await fetch(
            //             //     "https://www.google.com/recaptcha/api/siteverify",
            //             //     {
            //             //         method: "POST",
            //             //         headers: {"Content-Type": "application/x-www-form-urlencoded"},
            //             //         body: `secret=6LdJMY8aAAAAANdyReLymly6Sjw7pyCvgO2jkXHA&response=${token}`
            //             //     }
            //             // );

            // const r = await axios.post( "https://us-central1-atlasopen-new-site.cloudfunctions.net/verifyRecaptcha", {token})
            // const r = await fetch(
            //     "https://us-central1-atlasopen-new-site.cloudfunctions.net/verifyRecaptcha",
            //     {
            //         method: "POST",
            //         headers: {"Content-Type": "application/x-www-form-urlencoded"},
            //         body: {token}
            //     }
            // ).catch(e => console.log(e));
            // console.log(r);



            // const json = await r.json();

            // const body = JSON.stringify({ result });
            // console.log(r);
            //
            firebase
                .firestore()
                .collection("Enquiries")
                .add(data)
                .then(() => alert('We will be in touch shortly')).catch(e => console.log(e));
            handleServerResponse(true, "Thanks! for being with us", form);
        }
        catch(e){
            console.log(e)
        }
    };

    useEffect(() => {
        const textarea = document.querySelector("textarea");
        textarea?.addEventListener("keyup", e => {
            textarea.style.height = "auto";
            let scHeight = e.target.scrollHeight;
            textarea.style.height = `${scHeight}px`;
        })
    }, [])
    

    const onChangeHandler = e => {
        setValue({ ...value, [e.target.name]: e.target.value })
    };

    return (
        <FormWrap>
            {/*<GoogleReCaptcha*/}
            {/*    onVerify={token => {*/}
            {/*        setToken(token);*/}
            {/*    }}*/}
            {/*/>*/}
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                    <FormGroup className="halfwidth">
                        <Input
                            type="text"
                            name="firstname"
                            id="firstname"
                            placeholder="First name"
                            onChange={onChangeHandler}
                            ref={register({
                                required: 'First name*',
                            })}
                            {...inputStyle}
                        />
                        {errors.name && <span className="error">{errors.name.message}</span>}
                    </FormGroup>
                    <FormGroup className="halfwidth">
                        <Input
                            type="text"
                            name="lastname"
                            id="lastname"
                            placeholder="Last name"
                            onChange={onChangeHandler}
                            ref={register({
                                required: 'Last name*',
                            })}
                            {...inputStyle}
                        />
                        {errors.name && <span className="error">{errors.name.message}</span>}
                    </FormGroup>
                    <FormGroup className="halfwidth">
                        <Input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email"
                            onChange={onChangeHandler}
                            ref={register({
                                required: 'Email*',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "invalid email address"
                                }
                            })}
                        />
                        {errors.email && <span className="error">{errors.email.message}</span>}
                    </FormGroup>
                    <FormGroup className="halfwidth">
                        <Input
                            type="text"
                            name="number"
                            id="number"
                            placeholder="Phone Number"
                            onChange={onChangeHandler}
                            ref={register({
                                required: 'Number*',
                                pattern: {
                                    value: /^[+]*\d{7,15}/g,
                                    message: "invalid number"
                                }
                            })}
                            {...inputStyle}
                        />
                        {errors.number && <span className="error">{errors.number.message}</span>}
                    </FormGroup>
                    <FormGroup className="form-row">
                        <Input
                            type="text"
                            name="companyname"
                            id="companyname"
                            placeholder="Company name"
                            onChange={onChangeHandler}
                            ref={register({
                                required: 'Company*',
                                pattern: {
                                    message: "invalid Company"
                                }
                            })}
                            {...inputStyle}
                            />
                        {errors.companyname && <span className="error">{errors.companyname.message}</span>}
                    </FormGroup>
                </div>
                <div className="form-row">
                    <FormGroup style={{
                        height: "8rem",
                    }}>
                        <p 
                            className="required"
                            style={{ 
                                fontSize: "13px",
                                marginTop: "1rem",
                                marginBottom: "5px",
                                color: "#454545"
                            }}
                            >How can we help you?
                        </p>
                        <Textarea
                            className="textarea"
                            name="message"
                            id="textarea"
                            placeholder="Tell us about your project..."
                            onChange={onChangeHandler}
                            ref={register({
                                required: 'Message*',
                                minLength: { value: 10, message: "Minimum length is 10" }
                            })}
                        />
                        {errors.message && <span className="error">{errors.message.message}</span>}
                    </FormGroup>
                </div>
                <div className="form-row"
                    style={{ 
                        margin: "1rem 0",
                        position: "relative"
                    }}        
                    >
                    <FormGroup
                        style={{ 
                            margin: "5px 0",
                            marginTop: "-10px"
                        }}
                    >
                        <label style={{ 
                                    margin: "5px 0",
                                }}>
                            <input 
                                type="checkbox"
                                name="checkbox"
                                id="checkbox"
                                style={{ 
                                        width: "17px", 
                                        height: "17px" , 
                                        margin: "0 0",
                                        padding: "0 1rem",
                                    }}
                                         
                            />
                            <p
                                style={{ 
                                    fontSize: "12px",
                                    margin: "-45px 20px",
                                    padding: "1rem 5px",
                                }
                            }>Stay up to date with our Newsletter</p>
                        </label>
                    </FormGroup>
                </div>
                <div className="form-row">
                    <FormGroup>
                        <Button type="submit" layout={2} 
                            style={{ 
                                backgroundColor: "#3007B6", 
                                marginTop: "1rem" ,
                                fontSize: "10px"
                                }} 
                            disabled={serverState.submitting}>
                            Send Enquiry <i className="ti-arrow-right"></i>
                        </Button>
                        {serverState.status && (
                            <p className={`form-output ${!serverState.status.ok ? "errorMsg" : "success"}`}>
                                {serverState.status.msg}
                            </p>
                        )}
                    </FormGroup>
                </div>
            </Form>
        </FormWrap>
    )
}

ContactForm.propTypes = {
    inputStyle: PropTypes.object,
    textareaStyle: PropTypes.object
}

ContactForm.defaultProps = {
    inputStyle: {
        responsive: {
            xsmall: {
                mb: '20px'
            }
        },
    },
    textareaStyle: {
        mt: '40px',
        mb: '40px',
        responsive: {
            xsmall: {
                mt: '25px',
                mb: '25px'
            }
        }
    }
}

export default ContactForm
